export const Statuses = ['New', 'Updated', 'Purchased'] as const;

export const Units = [
	'piece(s)',
	'kilogram(s)',
	'litre(s)',
	'box(es)',
	'millilitre(s)',
	'milligram(s)',
	'carton(s)',
	'bottle(s)',
] as const;
